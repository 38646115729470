import { useMemo } from 'react'
import { LoadingDots } from '@components/ui'
import dynamic from 'next/dynamic'
import { useUI } from '@components/ui'

const Loading = () => (
  <div className="flex h-80 w-80 items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const Default = dynamic(() => import('templates/Collections/All/All'), {
  ...dynamicProps,
})

const V3 = dynamic(() => import('templates/Collections/V3'), {
  ...dynamicProps,
})

const NotFound = dynamic(() => import('pages/404'), {
  ...dynamicProps,
})

const Collections = (props) => {
  const { slug, metafields, collections, shop, seo, type } = props
  const { setGTM, gtmReady } = useUI()
  const current = metafields?.template || slug
  const targetTemp = (temp, tempMap) => {
    const template = tempMap.find((v) => {
      return v.rules && v.rules.some((fn) => fn(temp))
    })
    // 兼容路由异常导致next层返回404数据
    if (type === '404') {
      return <NotFound {...props} />
    }
    return (
      template?.templates ||
      tempMap.find((v) => v.name === 'Default')?.templates
    )
  }

  const productItems = useMemo(
    () =>
      collections?.products.map((item, index) => {
        return {
          item_id: item?.variants[0].sku,
          item_name: item.name,
          item_brand: shop.name,
          item_category: item.productType,
          item_variant: item?.variants[0].name,
          price: item?.price?.value,
          index: index,
          item_list_id: '',
          item_list_name: seo.title,
        }
      }),
    [collections?.products, shop.name, seo.title]
  )

  /* useEffect(() => {
    // 列表页触发ga4埋点
    const currencyCode = products[0]?.price?.currencyCode
    window['onloadGTM'] = () => {
      !gtmReady && setGTM(true)
      pageGTMEvent({
        event: 'view_item_list',
        page_group: `Product List Page_${seo.title}`,
        ecommerce: {
          currency: currencyCode,
          items: productItems,
        },
      })
    }
  }, [gtmReady, setGTM, productItems, seo.title, products])

  useEffect(() => {
    window?.onloadGTM && window.onloadGTM()
  }, []) */

  const template = useMemo(
    () =>
      targetTemp(current, [
        {
          name: 'Default',
          templates: <V3 {...props} />,
        },
      ]),
    [current, props]
  )

  return <>{template}</>
}

export default Collections
