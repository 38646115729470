import { Collections } from 'templates'
import type { GetStaticPropsContext, GetStaticPathsResult } from 'next'
import assignWith from 'lodash/assignWith'
import commerce from '@lib/api/commerce'
import { delHtmlTag, cleatShopKey, deepMerge } from '@lib/utils/tools'
import { ORIGIN_URL } from '@shopify/const'
import { languageTerritory } from '@commerce/seo/use-hreflang'
import getProductPersonalizePopular from '@lib/apis/get-product-personalize-popular'
import getCollectionQueryById from '@framework/utils/queries/get-collection-query-by-id'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'

const REVALIDATE_TIME = 200
/**
 * 根据id查询面包屑seo配置字段
 * @param belongsToCollectionGid
 * @param locale
 * @returns
 */
const getBreadcrumbsJsonLdName = async (
  belongsToCollectionGid: string,
  locale?: string
) => {
  try {
    if (belongsToCollectionGid) {
      const data: any = await fetchGraphqlApi<{
        variables: {
          id: string
        }
      }>({
        query: getCollectionQueryById,
        variables: { id: belongsToCollectionGid as string },
        locale,
      })
      return {
        breadcrumbsJsonLdName:
          data?.res?.collection?.MF_breadcrumbsJsonLdName?.value ||
          data?.res?.collection?.title,
        handle: data?.res?.collection?.handle,
      }
    }
  } catch (err) {
    return {}
  }
}

export async function getStaticProps({
  params,
  preview,
  locale,
  locales,
}: GetStaticPropsContext) {
  const config = { locale, locales }
  const handle = params!.category as string
  const siteInfoPromise = commerce.getSiteInfo({ config, preview })

  const collectionPromise = commerce.getCollection({
    variables: { slug: handle as string },
    config,
  })
  const { categories, brands, shop, metafields } = await siteInfoPromise

  if (metafields) {
    // 优化metafields
    const unUsedKeys = ['productListing']
    unUsedKeys.forEach((key) => {
      metafields[key] = null
    })
  }

  const { collection, error } = await collectionPromise

  if (!!error) {
    return {
      notFound: true,
      revalidate: REVALIDATE_TIME,
    }
  }
  if (!collection?.products) {
    return {
      notFound: true,
      revalidate: REVALIDATE_TIME,
    }
  }

  const collectionHrefLang = locales?.map((item: any) =>
    commerce.getCollection({
      variables: { slug: handle as string },
      config: {
        locale: item,
        locales,
      },
    })
  )

  const collectionLangResults = await Promise.all(collectionHrefLang || [])
  const languageAlternates: any[] = []
  locales?.forEach((lang, g) => {
    if (collectionLangResults[g]?.collection) {
      languageAlternates.push({
        hrefLang: languageTerritory(lang || 'us'),
        href: `${ORIGIN_URL}${lang === 'us' ? '' : '/' + lang}${
          collection.path
        }`,
      })
    }
  })
  if (languageAlternates.length > 0) {
    languageAlternates.unshift({
      hrefLang: 'x-default',
      href: languageAlternates[0].href,
    })
  }

  const seo = deepMerge(
    {
      title: delHtmlTag(collection.name),
      description: collection?.description || '',
      canonical:
        metafields?.seoSetting?.canonical ||
        `${ORIGIN_URL}${
          locale === 'us' ? '' : `/${locale}`
        }/collections/${handle}`,
      languageAlternates,
      openGraph: {
        type: 'website',
        title: delHtmlTag(collection.name),
        description: collection?.description || '',
        locale: languageTerritory(locale || 'us'),
        images: [
          {
            url: collection?.image?.url || '',
            width: '800',
            height: '600',
            alt: delHtmlTag(collection.name),
          },
        ],
      },
    },
    collection?.metafields?.seoSetting || {}
  )

  const { breadcrumbsJsonLdName, handle: belongsToCollectionHandle } =
    (await getBreadcrumbsJsonLdName(
      collection?.metafields?.belongsToCollection,
      locale
    )) ?? {}

  const mergeMetafields = assignWith(
    metafields,
    {
      ...collection.metafields,
      ...(breadcrumbsJsonLdName && { breadcrumbsJsonLdName }),
      ...(belongsToCollectionHandle && { belongsToCollectionHandle }),
    },
    (obj, page) => (!!page ? page : obj)
  )

  const recommendProducts = await getProductPersonalizePopular(locale)

  return {
    props: {
      type: 'collections',
      slug: handle,
      categories:
        categories?.map((cat) => ({
          slug: cat.slug,
          path: cat.path,
          name: cat.name,
          seo: cat.seo,
          description: cat.description,
        })) ?? [],
      collections: collection,
      brands,
      shop: cleatShopKey(shop),
      seo,
      metafields: mergeMetafields,
      recommendProducts,
    },
    revalidate: REVALIDATE_TIME,
  }
}

export function getStaticPaths(): GetStaticPathsResult {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export default Collections
